import React from "react";

class Audio extends React.Component{
constructor (props){
  super(props);
  this.state ={
    isPlaying: false, 
    Audio0Src: this.props.Audio0Play,
    Audio1Src: this.props.Audio1Play,
    Audio2Src: this.props.Audio2Play,
    Audio3Src: this.props.Audio3Play,
    Audio4Src: this.props.Audio4Play,
    Audio5Src: this.props.Audio5Play,
    AudioTrack: null
  };
  this.playerAudio = this.playerAudio.bind(this);
  this.AudioEnded = this.AudioEnded.bind(this);
  this.AudioChangeState = this.AudioChangeState.bind(this);
}

playerAudio (clip) {
  var BtnNumber = clip.split("_");
  for(const a of document.querySelectorAll('audio')) {
    if (a.className===clip){//if audio selected == btn classname
      if(a.paused === true){ //if this audio is not playing
        if (this.state.isPlaying === true){ //if any audio is already playing
          return;
        }
        if (this.state.AudioTrack !== a){
          a.currentTime = 0;
        }
        a.play();
        this.setState({AudioTrack: a});
        if (BtnNumber[1] === "0"){
          this.setState({Audio0Src: this.props.Audio0Pause});
        }
        else if (BtnNumber[1] === "1"){
          this.setState({Audio1Src: this.props.Audio1Pause});
        }
        else if (BtnNumber[1] === "2"){
          this.setState({Audio2Src: this.props.Audio2Pause});
        }
        else if (BtnNumber[1] === "3"){
          this.setState({Audio3Src: this.props.Audio3Pause});
        }
        else if (BtnNumber[1] === "4"){
          this.setState({Audio4Src: this.props.Audio4Pause});
        }
        else if (BtnNumber[1] === "5"){
          this.setState({Audio5Src: this.props.Audio5Pause});
        }
        //change src = audioXpause
        this.setState({isPlaying: true},this.AudioChangeState); 
      }
      else{ //if a audio is playing
        a.pause();
        if (BtnNumber[1] === "0"){
          this.setState({Audio0Src: this.props.Audio0Play});
        }
        else if (BtnNumber[1] === "1"){
          this.setState({Audio1Src: this.props.Audio1Play});
        }
        else if (BtnNumber[1] === "2"){
          this.setState({Audio2Src: this.props.Audio2Play});
        }
        else if (BtnNumber[1] === "3"){
          this.setState({Audio3Src: this.props.Audio3Play});
        }
        else if (BtnNumber[1] === "4"){
          this.setState({Audio4Src: this.props.Audio4Play});
        }
        else if (BtnNumber[1] === "5"){
          this.setState({Audio5Src: this.props.Audio5Play});
        }
        this.setState({isPlaying: false},this.AudioChangeState); 
      }
    }
  }
}
AudioEnded(){
  this.setState({isPlaying: false},this.AudioChangeState);
  this.setState({Audio0Src: this.props.Audio0Play});
  this.setState({Audio1Src: this.props.Audio1Play});
  this.setState({Audio2Src: this.props.Audio2Play});
  this.setState({Audio3Src: this.props.Audio3Play});
  this.setState({Audio4Src: this.props.Audio4Play});
  this.setState({Audio5Src: this.props.Audio5Play});
}
AudioChangeState()
{
  this.props.CheckPlaying(this.state.isPlaying);
}
  render(){

    return (
      <div className="AudioPage">
        <div className='AudioBtnGroup'>
          <div>
            <img className={this.props.className} src={this.state.Audio0Src} alt="Audio1" onClick={()=>{this.playerAudio(this.props.audioLang+'_0');}} />
          </div>
          <div>
            <img className={this.props.className} src={this.state.Audio1Src} alt="Audio2" onClick={()=>{this.playerAudio(this.props.audioLang+'_1');}}/>
          </div>
          <div>
            <img className={this.props.className} src={this.state.Audio2Src} alt="Audio3" onClick={()=>{this.playerAudio(this.props.audioLang+'_2');}}/>
          </div>
          <div>
            <img className={this.props.className} src={this.state.Audio3Src} alt="Audio4" onClick={()=>{this.playerAudio(this.props.audioLang+'_3');}}/>
          </div>
          <div>
            <img className={this.props.className} src={this.state.Audio4Src} alt="Audio5" onClick={()=>{this.playerAudio(this.props.audioLang+'_4');}}/>
          </div>
          <div>
            <img className={this.props.className} src={this.state.Audio5Src} alt="Audio6" onClick={()=>{this.playerAudio(this.props.audioLang+'_5');}}/>
          </div>
        </div>

        <audio className='audioClips-TC_0' onEnded={()=>{this.AudioEnded()}}>
          <source src={require("./AudioSource/TC/CAVO-01.mp3")}></source>
        </audio>
        <audio className='audioClips-TC_1' onEnded={()=>{this.AudioEnded()}}>
          <source src={require("./AudioSource/TC/CAVO-02.mp3")}></source>
        </audio>
        <audio className='audioClips-TC_2' onEnded={()=>{this.AudioEnded()}}>
          <source src={require("./AudioSource/TC/CAVO-03.mp3")}></source>
        </audio>
        <audio className='audioClips-TC_3' onEnded={()=>{this.AudioEnded()}}>
          <source src={require("./AudioSource/TC/CAVO-04.mp3")}></source>
        </audio>
        <audio className='audioClips-TC_4' onEnded={()=>{this.AudioEnded()}}>
          <source src={require("./AudioSource/TC/CAVO-05.mp3")}></source>
        </audio>
        <audio className='audioClips-TC_5' onEnded={()=>{this.AudioEnded()}}>
          <source src={require("./AudioSource/TC/CAVO-06.mp3")}></source>
        </audio>
        
        <audio className='audioClips-SC_0' onEnded={()=>{this.AudioEnded()}}>
          <source src={require("./AudioSource/SC/MAVO-01.mp3")}></source>
        </audio>
        <audio className='audioClips-SC_1' onEnded={()=>{this.AudioEnded()}}>
          <source src={require("./AudioSource/SC/MAVO-02.mp3")}></source>
        </audio>
        <audio className='audioClips-SC_2' onEnded={()=>{this.AudioEnded()}}>
          <source src={require("./AudioSource/SC/MAVO-03.mp3")}></source>
        </audio>
        <audio className='audioClips-SC_3' onEnded={()=>{this.AudioEnded()}}>
          <source src={require("./AudioSource/SC/MAVO-04.mp3")}></source>
        </audio>
        <audio className='audioClips-SC_4' onEnded={()=>{this.AudioEnded()}}>
          <source src={require("./AudioSource/SC/MAVO-05.mp3")}></source>
        </audio>
        <audio className='audioClips-SC_5' onEnded={()=>{this.AudioEnded()}}>
          <source src={require("./AudioSource/SC/MAVO-06.mp3")}></source>
        </audio>
        
        <audio className='audioClips-EN_0' onEnded={()=>{this.AudioEnded()}}>
          <source src={require("./AudioSource/EN/ENVO-01.mp3")}></source>
        </audio>
        <audio className='audioClips-EN_1' onEnded={()=>{this.AudioEnded()}}>
          <source src={require("./AudioSource/EN/ENVO-02.mp3")}></source>
        </audio>
        <audio className='audioClips-EN_2' onEnded={()=>{this.AudioEnded()}}>
          <source src={require("./AudioSource/EN/ENVO-03.mp3")}></source>
        </audio>
        <audio className='audioClips-EN_3' onEnded={()=>{this.AudioEnded()}}>
          <source src={require("./AudioSource/EN/ENVO-04.mp3")}></source>
        </audio>
        <audio className='audioClips-EN_4' onEnded={()=>{this.AudioEnded()}}>
          <source src={require("./AudioSource/EN/ENVO-05.mp3")}></source>
        </audio>
        <audio className='audioClips-EN_5' onEnded={()=>{this.AudioEnded()}}>
          <source src={require("./AudioSource/EN/ENVO-06.mp3")}></source>
        </audio>
      </div>
    );
  }
};

export default Audio;