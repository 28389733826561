import React from 'react';

class Button extends React.Component {
	constructor(props){
		super(props);
		this.state = {page:1};
	}
	render(){
		return (
				<img className={this.props.className} src={this.props.Image} alt="back" onClick={this.props.onClick}/>
		);
	}
};

export default Button;
