import React, { useRef } from 'react';
import './App.css';
import Button from './btn';
import Audio from './AudioPlayer';
import DeviceOrientation, { Orientation } from 'react-screen-orientation'

class App extends React.Component {
constructor(props){
super(props);
this.state = { page: 1, language: 0, AudioPlaying: false};

this.chooseTC = this.chooseTC.bind(this);
this.chooseSC = this.chooseSC.bind(this);
this.chooseEN = this.chooseEN.bind(this);

this.audioBtn = React.createRef();
this.ChangePageRefresh = this.ChangePageRefresh.bind(this);
this.IsAudioPlaying = this.IsAudioPlaying.bind(this);
}

chooseTC() {
	this.setState({page:2, language: 0},this.ChangePageRefresh);
	if (this.state.AudioPlaying === true){
		//stop all audio
		for(const a of document.querySelectorAll('audio')) {
			a.pause();
			a.currentTime = 0;
		}
	}
}
chooseSC () {
	this.setState({page:2, language: 1},this.ChangePageRefresh);
	if (this.state.AudioPlaying === true){
		//stop all audio
		for(const a of document.querySelectorAll('audio')) {
			a.pause();
			a.currentTime = 0;
		}
	}
}
chooseEN() {
	this.setState({page:2, language: 2},this.ChangePageRefresh);
	if (this.state.AudioPlaying === true){
		//stop all audio
		for(const a of document.querySelectorAll('audio')) {
			a.pause();
			a.currentTime = 0;
		}
	}
}

ChangePageRefresh(){
	this.audioBtn.current.AudioEnded();
}

IsAudioPlaying(state){
	this.setState({AudioPlaying: state});
}

render(){
	if (this.state.page === 1) {
	  	return (
		<DeviceOrientation lockOrientation={'portrait'} className='Main'>
			<Orientation className='Main' orientation='portrait' alwaysRender={false}>
					<div className='header'>
						<img className='Logo' src={require("./OtherImg/LOGO.png")} alt='NP360 Logo'/>
					</div>
					<div className='MainContent'>
						<img className='MainTitle' src={require("./OtherImg/Text-1.png")} alt="Title1"/>
						<div className="MainCenterGroup">
							<img className='MainSubtitle' src={require("./OtherImg/Text-2.png")} alt="Title2"/>
							<div className='MainBtnGroup'>
								<div className='MainChangeLangBtn'>
									<Button className='ImgButtonMain' onClick={this.chooseTC} Image={require("./ButtonImg/TC/CA-BIG.png")}/>
								</div>
								<div className='MainChangeLangBtn'>
									<Button className='ImgButtonMain' onClick={this.chooseSC} Image={require("./ButtonImg/SC/MA-BIG.png")}/>
								</div>
								<div className='MainChangeLangBtn'>
									<Button className='ImgButtonMain' onClick={this.chooseEN} Image={require("./ButtonImg/EN/ENG-BIG.png")}/>
								</div>
							</div>
						</div>
					</div>
        	</Orientation>
			<Orientation orientation='landscape'  alwaysRender={false}>
				<div className='orientationCover'>
					<header className='header'>
						<img className='Logo' src={require("./OtherImg/LOGO.png")} alt='NP360 Logo'/>
					</header>
					<img src={require("./OtherImg/LandToPort-v2.png")} alt="rotate" className='orientationImg'/>
				</div>
			</Orientation>
      </DeviceOrientation>
	  );
  } else{
	if (this.state.language === 0){
		return(
		<DeviceOrientation lockOrientation={'default'} >
			<Orientation orientation='portrait' alwaysRender={true} className='Page2'>
				<div className='header'>
					<img className='Logo' src={require("./OtherImg/LOGO.png")} alt='NP360 Logo'/>
				</div>

				<Audio lang={this.state.language}
				className='CHNAudioBtn'
				audioLang='audioClips-TC'
				ref = {this.audioBtn}
				Audio0Play = {require("./ButtonImg/TC/CA-1-Play.png")}
				Audio1Play = {require("./ButtonImg/TC/CA-2-Play.png")}
				Audio2Play = {require("./ButtonImg/TC/CA-3-Play.png")}
				Audio3Play = {require("./ButtonImg/TC/CA-4-Play.png")}
				Audio4Play = {require("./ButtonImg/TC/CA-5-Play.png")}
				Audio5Play = {require("./ButtonImg/TC/CA-6-Play.png")}
				
				Audio0Pause = {require("./ButtonImg/TC/CA-1-Pause.png")}
				Audio1Pause = {require("./ButtonImg/TC/CA-2-Pause.png")}
				Audio2Pause = {require("./ButtonImg/TC/CA-3-Pause.png")}
				Audio3Pause = {require("./ButtonImg/TC/CA-4-Pause.png")}
				Audio4Pause = {require("./ButtonImg/TC/CA-5-Pause.png")}
				Audio5Pause = {require("./ButtonImg/TC/CA-6-Pause.png")}
				CheckPlaying={this.IsAudioPlaying}
				asd="./ButtonImg/TC/CA-6-Pause.png"
				/>

				<div className='footer'>
					<Button className='Page2ChangeLangBtn' onClick={this.chooseSC} Image={require("./ButtonImg/SC/BTN-MA.png")}/>
					<Button className='Page2ChangeLangBtn' onClick={this.chooseEN} Image={require("./ButtonImg/EN/BTN-ENG.png")}/>
				</div>
			</Orientation>
			<Orientation orientation='landscape'  alwaysRender={false}>
				<div className='orientationCover'>
					<header className='header'>
						<img className='Logo' src={require("./OtherImg/LOGO.png")} alt='NP360 Logo'/>
					</header>
					<img src={require("./OtherImg/LandToPort-v2.png")} alt="rotate" className='orientationImg'/>
				</div>
			</Orientation>
		</DeviceOrientation>
		)
	}
	else if (this.state.language === 1){
		return(
		<DeviceOrientation lockOrientation={'portrait'} className='Page2'>
			<Orientation orientation='portrait' alwaysRender={true} className='Page2'>
				<div className='header'>
					<img className='Logo' src={require("./OtherImg/LOGO.png")} alt='NP360 Logo'/>
				</div>

				<Audio lang={this.state.language} 
				className='CHNAudioBtn'
				audioLang='audioClips-SC'
				ref = {this.audioBtn}
				Audio0Play = {require("./ButtonImg/SC/MA-1-Play.png")}
				Audio1Play = {require("./ButtonImg/SC/MA-2-Play.png")}
				Audio2Play = {require("./ButtonImg/SC/MA-3-Play.png")}
				Audio3Play = {require("./ButtonImg/SC/MA-4-Play.png")}
				Audio4Play = {require("./ButtonImg/SC/MA-5-Play.png")}
				Audio5Play = {require("./ButtonImg/SC/MA-6-Play.png")}

				Audio0Pause = {require("./ButtonImg/SC/MA-1-Pause.png")}
				Audio1Pause = {require("./ButtonImg/SC/MA-2-Pause.png")}
				Audio2Pause = {require("./ButtonImg/SC/MA-3-Pause.png")}
				Audio3Pause = {require("./ButtonImg/SC/MA-4-Pause.png")}
				Audio4Pause = {require("./ButtonImg/SC/MA-5-Pause.png")}
				Audio5Pause = {require("./ButtonImg/SC/MA-6-Pause.png")}
				CheckPlaying={this.IsAudioPlaying}
				asd="./ButtonImg/SC/MA-6-Pause.png"
				/>

				<div className='footer'>
					<Button className='Page2ChangeLangBtn' onClick={this.chooseEN} Image={require("./ButtonImg/EN/BTN-ENG.png")}/>
					<Button className='Page2ChangeLangBtn' onClick={this.chooseTC} Image={require("./ButtonImg/TC/BTN-CA.png")}/>
				</div>
			</Orientation>
			<Orientation orientation='landscape'  alwaysRender={false}>
				<div className='orientationCover'>
						<header className='header'>
							<img className='Logo' src={require("./OtherImg/LOGO.png")} alt='NP360 Logo'/>
						</header>
					<img src={require("./OtherImg/LandToPort-v2.png")} alt="rotate" className='orientationImg'/>
				</div>
			</Orientation>
		</DeviceOrientation>
		)
	} else if (this.state.language === 2){
		return(
		<DeviceOrientation lockOrientation={'portrait'} className='Page2'>
			<Orientation orientation='portrait' alwaysRender={true} className='Page2'>
				<div className='header'>
					<img className='Logo' src={require("./OtherImg/LOGO.png")} alt='NP360 Logo'/>
				</div>

				<Audio lang={this.state.language}
				className='ENGAudioBtn'
				audioLang='audioClips-EN'
				ref = {this.audioBtn}
				Audio0Play = {require("./ButtonImg/EN/ENG-1-Play.png")}
				Audio1Play = {require("./ButtonImg/EN/ENG-2-Play.png")}
				Audio2Play = {require("./ButtonImg/EN/ENG-3-Play.png")}
				Audio3Play = {require("./ButtonImg/EN/ENG-4-Play.png")}
				Audio4Play = {require("./ButtonImg/EN/ENG-5-Play.png")}
				Audio5Play = {require("./ButtonImg/EN/ENG-6-Play.png")}
				
				Audio0Pause = {require("./ButtonImg/EN/ENG-1-Pause.png")}
				Audio1Pause = {require("./ButtonImg/EN/ENG-2-Pause.png")}
				Audio2Pause = {require("./ButtonImg/EN/ENG-3-Pause.png")}
				Audio3Pause = {require("./ButtonImg/EN/ENG-4-Pause.png")}
				Audio4Pause = {require("./ButtonImg/EN/ENG-5-Pause.png")}
				Audio5Pause = {require("./ButtonImg/EN/ENG-6-Pause.png")}
				CheckPlaying={this.IsAudioPlaying}
				asd="./ButtonImg/EN/ENG-6-Pause.png"
				/>

				<div className='footer'>
					<Button className='Page2ChangeLangBtn' onClick={this.chooseTC} Image={require("./ButtonImg/TC/BTN-CA.png")}/>
					<Button className='Page2ChangeLangBtn' onClick={this.chooseSC} Image={require("./ButtonImg/SC/BTN-MA.png")}/>
				</div>
			</Orientation>
			<Orientation orientation='landscape'  alwaysRender={false}>
				<div className='orientationCover'>
					<header className='header'>
						<img className='Logo' src={require("./OtherImg/LOGO.png")} alt='NP360 Logo'/>
					</header>
					<img src={require("./OtherImg/LandToPort-v2.png")} alt="rotate" className='orientationImg'/>
				</div>
			</Orientation>
      	</DeviceOrientation>
		)
	}
  }
};
}

export default App;
